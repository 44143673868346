import authApi, { setApiAuthorizationHeader } from "../authinstance";
import keys from "../keys";
const queryString = require('query-string');

class Auth {
    async get_city(data) {
        const { ...params } = data;
        let query = queryString.stringify(params);
        try {
            // setApiAuthorizationHeader(token)
            const response = await authApi.get(`${keys.SERVER_URL}utils/city/?${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_country(data) {

        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.get(`${keys.SERVER_URL}utils/country/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_gender() {
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.get(`${keys.SERVER_URL}utils/gender/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_language() {
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.get(`${keys.SERVER_URL}utils/language/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async set_files(data) {
        try {
            // setApiAuthorizationHeader(data.token)
            const response = await authApi.post(`${keys.SERVER_URL}/files/files-object/`, data);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_notification(data) {
        const { token, ...params } = data;
        let query = queryString.stringify(params);
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}notification/notification/?${query}`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_notification_count(token) {
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}notification/get-unseen-notification-count/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async send_change_email_code(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/send-change-email-code/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async check_change_email_code(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/check-change-email-code/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async send_change_phone_code(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/send-change-phone-number-code/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async change_password(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/change-password/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async set_password(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/set-password/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async check_change_phone_code(data) {
        const { token, ...rest } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.post(`${keys.SERVER_URL}userdetails/check-change-phone-number-code/`, rest);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_footer(data) {
        const { token } = data;
        try {
            setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}utils/footer-context/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
    async get_menu() {
        // const { token } = data;
        try {
            // setApiAuthorizationHeader(token);
            const response = await authApi.get(`${keys.SERVER_URL}utils/navigation/`);
            return response.data;
        } catch (error) {
            return error.response;
        }
    }
}
const category = new Auth();
export default category;
