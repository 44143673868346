import { General } from "../constants";

export const get_city = (data) => ({
    type: General.GET_CITY,
    payload: data
})
export const city_loading = (data) => ({
    type: General.GET_CITY_LOADING,
})
export const get_country = (data) => {
    return ({
        type: General.GET_COUNTRY,
        payload: data
    })
}
export const get_city_saga = (data) => ({
    type: General.GET_CITY_SAGA,
    payload: data
})
export const get_country_saga = (data) => {
    return ({
        type: General.GET_COUNTRY_SAGA,
        payload: data
    })
}
export const get_gender = (data) => ({
    type: General.GET_GENDER,
    payload: data
})
export const get_gender_saga = (data) => {
    return ({
        type: General.GET_GENDER_SAGA,
        payload: data
    })
}
export const get_language_saga = () => {
    return ({
        type: General.GET_LENGUAGE_SAGA,
    })
}
export const get_language = (data) => {
    return ({
        type: General.GET_LENGUAGE,
        payload: data
    })
}
export const set_menu = (data) => {
    return ({
        type: General.SET_MENU,
        payload: data
    })
}
export const get_menu = (data) => {
    return ({
        type: General.GET_MENU,
        payload: data
    })
}
// GET_LOADING_NOTIFICATION
// GET_MY_PERSONAL_DETAILS_SAGA: "GET_MY_PERSONAL_DETAILS_SAGA",
    // GET_ME: "GET_ME"