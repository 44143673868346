import { My_personal_details } from "../constants";
export const edit_my_data = (data) => ({
    type: My_personal_details.EDIT_MY_PERSONAL_DETAILS,
    payload: data
});
export const edit_my_data_saga = (data) => {
    return ({
        type: My_personal_details.EDIT_MY_PERSONAL_DETAILS_SAGA,
        payload: data
    });
};
export const get_inputs_values = (data) => {
    return ({
        type: My_personal_details.GET_INPUT_VALUES,
        payload: data
    });
};
export const set_email = (data) => ({
    type: My_personal_details.SET_EMAIL,
    payload: data
});
export const set_phone = (data) => ({
    type: My_personal_details.SET_PHONE,
    payload: data
});
export const get_my_data_saga = (data) => {
    return ({
        type: My_personal_details.GET_MY_PERSONAL_DETAILS_SAGA,
        payload: data
    });
};
export const get_me_act = (data) => {
    return ({
        type: My_personal_details.GET_ME,
        payload: data
    });
};
export const set_my_info_act = (data) => {
    return ({
        type: My_personal_details.SET_MY_INFO,
        payload: data
    });
};
export const set_file_saga = (data) => {
    return ({
        type: My_personal_details.SET_FILES_SAGA,
        payload: data
    });
};
export const submitted = () => {
    return ({
        type: My_personal_details.SUBMITTED,
    });
};
export const help_act = (data) => { //HELP ACTIONS
    return ({
        type: My_personal_details.HELP,
        payload: data
    });
};
export const help_saga = (data) => {
    return ({
        type: My_personal_details.HELP_SAGA,
        payload: data
    });
};
export const help_submitted = () => {
    return ({
        type: My_personal_details.HELP_SUBMITTED,
    });
};
export const help_input = (data) => {
    return ({
        type: My_personal_details.HELP_INPUT,
        payload: data
    });
};
export const help_loading = () => {
    return ({
        type: My_personal_details.HELP_LOADING
    });
};
export const settings_act = (data) => { //SETTINGS ACTIONS
    return ({
        type: My_personal_details.SETTINGS,
        payload: data
    });
};
export const settings_data = (data) => {
    return ({
        type: My_personal_details.SETTINGS_DATA,
        payload: data
    });
};
export const get_settings_saga = (data) => {
    return ({
        type: My_personal_details.GET_SETTINGS_SAGA,
        payload: data
    });
};
export const settings_saga = (data) => {
    return ({
        type: My_personal_details.SETTINGS_SAGA,
        payload: data
    });
};
export const settings_submitted = () => {
    return ({
        type: My_personal_details.SETTINGS_SUBMITTED,
    });
};
export const settings_input = (data) => {
    return ({
        type: My_personal_details.SETTINGS_INPUT,
        payload: data
    });
};
export const settings_loading = () => {
    return ({
        type: My_personal_details.SETTINGS_LOADING
    });
};
export const edit_my_email = (data) => {
    return ({
        type: My_personal_details.EDIT_MY_EMAIL,
        payload: data
    });
};
export const edit_my_email_saga = (data) => {
    return ({
        type: My_personal_details.EDIT_MY_EMAIL_SAGA,
        payload: data
    });
};
export const check_my_email_saga = (data) => {
    return ({
        type: My_personal_details.CHACK_MY_EMAIL_SAGA,
        payload: data
    });
};
export const set_email_input_values = (data) => {
    return ({
        type: My_personal_details.SET_EMAIL_INPUT_VALUES,
        payload: data
    });
};
export const email_submmited = () => {
    return ({
        type: My_personal_details.EMAIL_SUBMITTED,
    });
};
export const check_email_submmited = () => {
    return ({
        type: My_personal_details.CHACK_EMAIL_SUBMITTED,
    });
};
export const email_loading = () => {
    return ({
        type: My_personal_details.EMAIL_LOADING,
    });
};
export const edit_my_phone = (data) => {   //EDIT PHONE NUMBER
    return ({
        type: My_personal_details.EDIT_MY_PHONE,
        payload: data
    });
};
export const edit_my_phone_saga = (data) => {
    return ({
        type: My_personal_details.EDIT_MY_PHONE_SAGA,
        payload: data
    });
};
export const check_my_phone_saga = (data) => {
    return ({
        type: My_personal_details.CHACK_MY_PHONE_SAGA,
        payload: data
    });
};
export const set_phone_input_values = (data) => {
    return ({
        type: My_personal_details.SET_PHONE_INPUT_VALUES,
        payload: data
    });
};
export const phone_submmited = () => {
    return ({
        type: My_personal_details.PHONE_SUBMITTED,
    });
};
export const check_phone_submmited = () => {
    return ({
        type: My_personal_details.CHACK_EMAIL_SUBMITTED,
    });
};
export const phone_loading = () => {
    return ({
        type: My_personal_details.PHONE_LOADING,
    });
};
export const change_language = (data) => {
    return ({
        type: My_personal_details.CHANGE_LANGUAGE,
        payload: data
    });
};
export const set_footer = (data) => {
    return ({
        type: My_personal_details.SET_FOOTER,
        payload: data
    });
};
export const get_footer = (data) => {
    return ({
        type: My_personal_details.GET_FOOTER,
        payload: data
    });
};
export const change_password_saga = (data) => {
    return ({
        type: My_personal_details.CHANGE_PASSWORD,
        payload: data
    });
};
export const set_password_saga = (data) => {
    return ({
        type: My_personal_details.SET_PASSWORD,
        payload: data
    });
};